import React from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
  } from 'react-router-dom';
import BackgroundSlideshow from 'react-background-slideshow';
// import {
//     PrivateRouteAdmin,
//     PrivateRouteODCEC,
//     PrivateRouteHostess,
// } from '../common/privateRoute';

// import Home from './Items/Home';
// import About from './Items/About';
// import Header from './Items/Header';
// import RegisterGuest from './Items/RegisterGuest';
// import RegisterFornitore from './Items/RegisterFornitore';
// import Register from './Items/Register';
// import PrenotazioneEventi from './Items/PrenotazioneEventi';
// import ProgrammaEvento from './Items/ProgrammaEvento';
// import ManageAccount from './Items/ManageAccount';
// import ResetPassword from './Items/ResetPassword';
// import EventiArchiviati from './Items/EventiArchiviati';
// import EventoArchiviato from './Items/EventoArchiviato';
// import LiveStreaming from './Items/LiveStreaming';
// import CreateEvent from './Items/Admin/CreateEvent';
// import EditEvent from './Items/Admin/EditEvent';
// import AssociazioniEsterne from './Items/Admin/AssociazioniEsterne';
// import CreateAssociazione from './Items/Admin/CreateAssociazione';
// import EditAssociazione from './Items/Admin/EditAssociazione';
// import HandleEvents from './Items/Admin/HandleEvents';
// import HandleEventsForReport from './Items/Admin/HandleEventsForReport';
// import ReportEvento from './Items/Admin/ReportEvento';
// import ReportAttivita from './Items/Admin/ReportAttivita';
// import ReportAttivitaPerOrdine from './Items/Admin/ReportAttivitaPerOrdine';
// import ReportAttivitaPerOspitiFornitori from './Items/Admin/ReportAttivitaPerOspitiFornitori';
// import EventsToCNDCEC from './Items/Admin/EventsToCNDCEC';
// import BookSubscriberToEventCNDCEC from './Items/Admin/BookSubscriberToEventCNDCEC';
// import EventiAccettazione from './Items/Hostess/EventiAccettazione';
// import AccettazioneUtentiEvento from './Items/Hostess/AccettazioneUtentiEvento';
// import EventsToSecretariats from './Items/Secretariats/EventsToSecretariats';
// import EventsToSecretariatForReport from './Items/Secretariats/EventsToSecretariatForReport';
// import BookSubscriberToEvent from './Items/Secretariats/BookSubscriberToEvent';
// import HandleSubscribersToEvent from './Items/Secretariats/HandleSubscribersToEvent';

import PageNotFound from './Items/PageNotFound';

const images = [
    'https://cndcecpsstorage.blob.core.windows.net/static-files/tst/001.jpg',
    'https://cndcecpsstorage.blob.core.windows.net/static-files/tst/002.jpg',
    'https://cndcecpsstorage.blob.core.windows.net/static-files/tst/003.jpg',
    'https://cndcecpsstorage.blob.core.windows.net/static-files/tst/004.jpg',
    'https://cndcecpsstorage.blob.core.windows.net/static-files/tst/005.jpg',
    'https://cndcecpsstorage.blob.core.windows.net/static-files/tst/006.jpg',
];

export default function App() {
    return (
    <>
        <p>React client injected</p>
        {/* <Router>
            <>
                <Header />
                <Switch>
                    <Route exact path="/">
                        <Home />
                    </Route>
                    <Route path="/about">
                        <About />
                    </Route>
                    <Route path="/registrazione_ospiti">
                        <RegisterGuest />
                    </Route>
                    <Route path="/registrazione_fornitori">
                        <RegisterFornitore />
                    </Route>
                    <Route path="/registrazione">
                        <Register />
                    </Route>
                    <Route path="/prenotazione_evento/:id">
                        <PrenotazioneEventi />
                    </Route>
                    <Route path="/programma_evento/:id">
                        <ProgrammaEvento />
                    </Route>
                    <Route path="/eventi_archiviati">
                        <EventiArchiviati />
                    </Route>
                    <Route path="/evento_archiviato/:id">
                        <EventoArchiviato />
                    </Route>
                    <Route path="/gestione_account">
                        <ManageAccount />
                    </Route>
                    <Route path="/reset_password/:token?">
                        <ResetPassword />
                    </Route>
                    <Route path="/live/fruizione/:id">
                        <LiveStreaming />
                    </Route>
                    <PrivateRouteAdmin path="/admin/gestione_eventi" component={HandleEvents} />
                    <PrivateRouteAdmin path="/admin/creazione_evento/:rel?" component={CreateEvent} />
                    <PrivateRouteAdmin path="/admin/modifica_evento/:id/:rel?" component={EditEvent} />
                    <PrivateRouteAdmin path="/admin/report_partecipazioni_eventi" component={HandleEventsForReport} />
                    <PrivateRouteAdmin path="/admin/report_parecipazione_evento/:id/:rel?" component={ReportEvento} />
                    <PrivateRouteAdmin path="/admin/report_parecipazione_attivita/:id/:rel?" component={ReportAttivita} />
                    <PrivateRouteAdmin path="/admin/dettaglio_per_ordine/:id/:rel?" component={ReportAttivitaPerOrdine} />
                    <PrivateRouteAdmin path="/admin/dettaglio_per_opiti_fornitori/:id/:rel?" component={ReportAttivitaPerOspitiFornitori} />
                    <PrivateRouteAdmin path="/admin/prenotazione_iscritti_eventi" component={EventsToCNDCEC} />
                    <PrivateRouteAdmin path="/admin/prenotazione_evento/:id/:rel?" component={BookSubscriberToEventCNDCEC} />
                    <PrivateRouteAdmin path="/admin/associazioni_esterne" component={AssociazioniEsterne} />
                    <PrivateRouteAdmin path="/admin/creazione_associazione/:rel?" component={CreateAssociazione} />
                    <PrivateRouteAdmin path="/admin/modifica_associazione/:id/:rel?" component={EditAssociazione} />
                    <PrivateRouteHostess path="/accettazione/eventi" component={EventiAccettazione} />
                    <PrivateRouteHostess path="/accettazione/iscrizione_utente/:id/:rel?/:cf?" component={AccettazioneUtentiEvento} />
                    <PrivateRouteODCEC path="/segreterie/eventi" component={EventsToSecretariats} />
                    <PrivateRouteODCEC path="/segreterie/iscrizione_utente_evento/:id/:rel?" component={BookSubscriberToEvent} />
                    <PrivateRouteODCEC path="/segreterie/report_eventi" component={EventsToSecretariatForReport} />
                    <PrivateRouteODCEC path="/segreterie/gestione_evento_utenti/:id/:rel?" component={HandleSubscribersToEvent} />
                    <Route>
                        <PageNotFound />
                    </Route>
                </Switch>
            </>
        </Router> */}
    </>
    );
}

// U+FEFF;
import 'jquery';
import 'popper.js';
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar';
import 'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.css';
// import 'owl.carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { disableReactDevTools } from '@fvilers/disable-react-devtools';

import '../scss/main.scss';

import loadingOverlay from './lib/common/loadingOverlay';
import configHomePage from './lib/configpages/home';

const $ = require('jquery');

const EventConsulting = {
  common: {
    init() {
      loadingOverlay();
    },
    finalize() {
      // called only from page with main-content/data-controller set
    },
  },
  home: {
    init() {
      configHomePage();
    },
  },
};

const UTIL = {
  fire(func, funcname = 'init') {
    const namespace = EventConsulting;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
      namespace[func][funcname]();
    }
  },
  loadEvents() {
    // hit up common first.
    UTIL.fire('common');
    const mainContent = document.getElementById('eventconsulting-client');
    if (mainContent === 'undefined' || mainContent === null) {
      return;
    }
    const controller = mainContent.getAttribute('data-controller');
    if (controller !== '') {
      UTIL.fire(controller);
    }
    UTIL.fire('common', 'finalize');
  },
};

/* eslint-disable func-names, id-length, no-unused-expressions */
$(() => {
  UTIL.loadEvents();
});
// $(document).ready(() => {
//   UTIL.loadEvents();
// });

if (process.env && process.env.NODE_ENV === 'production') {
  disableReactDevTools();
}
